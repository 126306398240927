import {
	ApartmentRounded,
	HourglassEmpty,
	LocationCity,
} from "@material-ui/icons";

const config = {
	name: "Utility Dashboard",
	brand: {
		main: "#fdb713",
	},
	apiUrl: "/api-college-park/api",
	authUrl: "/api-college-park/api/auth",
	basename: "/college-park",
	documentationURL: "https://terpfootprints.umd.edu/docs",
	surveyURL:
		"https://docs.google.com/forms/d/e/1FAIpQLSehPFILRbfuQ2mam7vxZEinZ1EFdwW-gP70k6t_EI7zbz01sw/viewform",
	LoadingIcon: HourglassEmpty,
	organization: {
		name: "City of College Park",
		location: "College Park, MD",
		nameShort: "CCP",
		type: "City",
		orgIcon: LocationCity,
		logoBanner: "images/logos/umd_logo.png",
		logo: "images/logos/umd_icon_logo.png",
		reportLogo: "images/logos/umd_globe.png",
		orgImage: "images/logos/umd_campus.jpg",
		fiscalYearMonthStart: 6,
	},
	department: {
		leader: "Director",
		name: "Public Works",
		leaderImage: "images/reports/director.png",
		groupImage: "images/reports/FM_DepartmentLogo.png",
		address: "9217 51st Ave College Park, MD 20740",
		contact: { phone: "240-487-3590", email: "publicworks@collegeparkmd.gov" },
	},
	auth: {
		levels: [
			{ id: "public", label: "Public Users" },
			{ id: "general", label: "General Users" },
			{ id: "advanced", label: "Advanced Users" },
			{
				id: "facilities",
				label: "Public Works Users",
			},
			{ id: "admin", label: "Admin Users" },
			{ id: "dev", label: "Development Users" },
		],
	},
	map: {
		defaultCenter: [-76.928101, 38.994005],
		icon: ApartmentRounded,
	},
	baselineYear: new Date(2018, 0),
	host: {
		logo: "/images/host/icon.png",
	},
};

document.title = config.name;
export default config;
