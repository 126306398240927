// https://github.com/react-ga/react-ga/wiki/Using-history-for-GA-Tracking
import ReactGA from "react-ga";

export const initializeGA = () => {
	ReactGA.initialize(String(process.env.REACT_APP_GA_ID));
};
export const logPageView = (history) => {
	if (process.env.NODE_ENV !== "development") {
		history.listen((location) => {
			const page = location.pathname || window.location.pathname;
			ReactGA.set({ page: page });
			ReactGA.pageview(page);
			// console.log(`Page View logged for: ${page}`);
		});
	}
};
