import React from "react";
import { queryCache } from "react-query";
import * as auth from "utils/auth-provider";
import { client } from "utils/api-client";
import * as userAPI from "utils/users";
import { useAsync } from "utils/useAsync";
import Loading from "components/Status/Loading";
import Error from "components/Status/Error";
import { hasLocal, getLocal, saveLocal } from "utils/localStorage";

function bookmarksCheck({ buildings, portfolio }) {
	if (!hasLocal("activePortfolio") && portfolio) {
		saveLocal(portfolio);
	}
	if (!hasLocal("recentBuildings") && buildings) {
		saveLocal("recentBuildings", buildings);
	}
}
async function bootstrapAppData() {
	let user = null;
	const token = await auth.getToken();
	if (token) {
		const data = await client("bootstrap", { token });
		user = data.user;
		// bookmarksCheck(user.bookmarks);
		user = {
			...user,
			notifications: [],
			showHVACReporting:
				!user.showHVACReporting && user.showHVACReporting !== false
					? user.scopes.includes("facilities")
					: user.showHVACReporting,
			// newUser: true,
		};

		// if (user?.expiresAt && new Date(user?.expiresAt) < new Date()) {
		// 	auth.logout();
		// 	queryCache.clear();
		// 	return {};
		// }
	}

	return user;
}

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

function AuthProvider(props) {
	const {
		data: user,
		status,
		error,
		isLoading,
		isIdle,
		isError,
		isSuccess,
		run,
		setData,
	} = useAsync();

	React.useEffect(() => {
		const appDataPromise = bootstrapAppData();
		run(appDataPromise);
	}, [run]);

	// AUTHENTICATION
	const login = React.useCallback(
		(form) => auth.login(form).then((user) => setData(user)),
		[setData]
	);
	const thirdPartyLogin = React.useCallback(
		({ service, ticket }) =>
			auth.thirdPartyLogin({ service, ticket }).then((user) => setData(user)),
		[setData]
	);
	const register = React.useCallback(
		(form) => auth.register(form).then((user) => setData(user)),
		[setData]
	);

	const userHasScope = React.useCallback(
		(requiredScopes) => {
			if (!Array.isArray(requiredScopes)) requiredScopes = [requiredScopes];
			if (!user || !user.hasOwnProperty("scopes")) return false;
			if (!requiredScopes || requiredScopes.length === 0) return true;
			return requiredScopes
				.map((s) => user.scopes.indexOf(s))
				.some((s) => s !== -1);
		},
		[user]
	);
	const logout = React.useCallback(() => {
		auth.logout();
		queryCache.clear();
		setData(null);
	}, [setData]);

	// USER SETTINGS
	const setPortfolioBookmark = React.useCallback((_id) =>
		userAPI.setPortfolioBookmark(_id).then((bookmarks) => {
			return setData({ ...user, bookmarks });
		})
	);
	const setBuildingBookmark = React.useCallback((_id) =>
		userAPI.setBuildingBookmark(_id).then((bookmarks) => {
			return setData({ ...user, bookmarks });
		})
	);
	const updateUser = React.useCallback((changes) => {
		return setData(Object.assign(user, changes));
	});
	const clearAllNotifications = React.useCallback(() => {
		return setData(Object.assign(user, { notifications: [] }));
	});
	const value = React.useMemo(
		() => ({
			user,
			login,
			logout,
			register,
			updateUser,
			clearAllNotifications,
			thirdPartyLogin,
			userHasScope,
			setPortfolioBookmark,
			setBuildingBookmark,
		}),
		[
			user,
			login,
			logout,
			register,
			updateUser,
			clearAllNotifications,
			thirdPartyLogin,
			userHasScope,
			setPortfolioBookmark,
			setBuildingBookmark,
		]
	);

	if (isLoading || isIdle) {
		return <Loading />;
	}

	if (isError) {
		return <Error error={error} />;
	}

	if (isSuccess) {
		return <AuthContext.Provider value={value} {...props} />;
	}

	throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`);
	}
	return context;
}

function useClient() {
	const { user } = useAuth();
	const token = user?.token;
	return React.useCallback(
		(endpoint, config) => client(endpoint, { ...config, token }),
		[token]
	);
}
function useAuthenticatedFetcher() {
	const { user } = useAuth();
	const token = user?.token;
}
export { AuthProvider, useAuth, useClient };
