import config from "config";
const authURL = config.authUrl;
const localStorageKey = process.env.REACT_APP_localStorageKey;

function getToken() {
	// if we were a real auth provider, this is where we would make a request
	// to retrieve the user's token. (It's a bit more complicated than that...
	// but you're probably not an auth provider so you don't need to worry about it).
	return window.localStorage.getItem(localStorageKey);
}

async function handleUserResponse(response) {
	const { user } = response;

	await window.localStorage.setItem(localStorageKey, user.token);
	return user;
}

function login({ email, password }) {
	return client("login", { email, password }).then(handleUserResponse);
}

function register({ email, username, password }) {
	return client("signup", { email, username, password }).then(
		handleUserResponse
	);
}

async function logout() {
	window.localStorage.removeItem(localStorageKey);
}

function checkAuthMethod({ email }) {
	return client(`authCheck`, { email })
		.then(({ errors, authMethod }) => {
			if (errors) console.error(errors);
			return authMethod;
		})
		.catch((err) => {
			console.error(err);
			return null;
		});
}
function thirdPartyLogin({ service, ticket, initialpageRequest }) {
	return client("thirdPartyAuthenticate", { service, ticket }).then(
		handleUserResponse
	);
}

function resetPassword({ username, password, newPassword, email }) {
	return client("reset-password", {
		email,
		username,
		password,
		newPassword,
	});
}

function requestPasswordReset({ username, email, pathname }) {
	let resetLink =
		window.location.href.split(pathname)[0] + "/user/reset-password";
	return client("request-reset", { email, username, resetLink }).then(
		handleUserResponse
	);
}

// Won't work with current client setup

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client

async function client(endpoint, data) {
	const config = {
		method: "POST",
		body: JSON.stringify(data),
		headers: { "Content-Type": "application/json" },
	};

	return window
		.fetch(`${authURL}/${endpoint}`, config)
		.then(async (response) => {
			const data = await response.json();
			if (response.ok) {
				return data;
			} else {
				return Promise.reject(data);
			}
		});
}

export {
	getToken,
	login,
	thirdPartyLogin,
	register,
	logout,
	checkAuthMethod,
	resetPassword,
	requestPasswordReset,
	localStorageKey,
};
