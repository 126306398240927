import React, { useEffect } from "react";

export const saveLocal = (key, value) => {
	window.localStorage.setItem(key, JSON.stringify(value));
};
export const hasLocal = (key) => {
	return Boolean(window.localStorage.getItem(key));
};
export const removeLocal = (key) => {
	return window.localStorage.removeItem(key);
};

export const getLocal = (key) => {
	return window.localStorage.getItem(key)
		? JSON.parse(window.localStorage.getItem(key))
		: null;
};
export const getItem = (key) => {
	if (typeof key !== "string") return null;
	if (localStorage.getItem(key) === null) {
		return null;
	}

	try {
		let value = JSON.parse(localStorage.getItem(key));
		return value;
	} catch (error) {
		let value = localStorage.getItem(key);
		if (value === "undefined" || value === "null") value = null;
		return value;
	}
};
export const setItem = (key, value) => {
	if (typeof value === "object") {
		localStorage.setItem(key, JSON.stringify(value));
	} else {
		localStorage.setItem(key, value);
	}
};
// Source: https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistedState(key, defaultValue) {
	const [state, setState] = React.useState(() => getItem(key) || defaultValue);
	// let local = getItem(key);
	useEffect(() => {
		// if (local !== state) setState(getItem(key));
		setItem(key, state);
	}, [key, state]);
	return [state, setState];
}

export async function checkThirdPartyCookies() {
	let globalCookies = navigator.cookieEnabled;

	if (globalCookies) {
		return true;
	} else {
		return false;
	}
}
