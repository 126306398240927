import React, { useEffect } from "react";
import "styles/App.css";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { logPageView } from "routes/gaTracker";
import ErrorPage from "components/Status/Error";
import { useAuth } from "contexts/auth-context";
import Loading from "components/Status/Loading.js";
const UnAuthApp = React.lazy(() => import("containers/Auth/Authentication"));
const AuthenticatedApp = React.lazy(() => import("./App_authenticated"));

const useStyles = makeStyles((theme) => ({
	layout: {
		boxSizing: "border-box",
		display: "flex",
		flex: "auto",
		flexDirection: "column",
		minHeight: 0,
		background: "#f0f2f5",
		// "& *": {
		// 	boxSizing: "border-box",
		// },
	},
}));

function App() {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useAuth();
	useEffect(() => {
		logPageView(history);
	}, [history]);

	return (
		<div className={classes.layout}>
			<React.Suspense fallback={<Loading />}>
				{user ? <AuthenticatedApp /> : <UnAuthApp />}
			</React.Suspense>
		</div>
	);
}
// https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
class ErrorCatch extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error(error, errorInfo);
	}
	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorPage />;
		}
		return <App />;
	}
}
export default App;
