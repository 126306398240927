import { useQuery } from "react-query";
import { useClient } from "contexts/auth-context";
import { client as api } from "utils/api-client";
import { getToken } from "utils/auth-provider";
async function sendFeedback({ email, comments, topic, uploads = [] }) {
	let formData = new FormData();
	for (let i = 0, length1 = uploads.length; i < length1; i++) {
		formData.append("files", uploads[i], uploads[i].name);
	}
	formData.append("email", email);
	formData.append("comments", comments);
	formData.append("topic", JSON.stringify(topic));
	// let body = {
	// 	email,
	// 	comment: comments,
	// 	topic,
	// };
	console.log([...formData.keys()]);
	console.log([...formData.values()]);
	return api(`users/feedback`, {
		formData,
		token: getToken(),
		method: "POST",
	});
}
const queryConfig = {
	staleTime: Infinity, //1000 * 60 * 30
	cacheTime: Infinity, //1000 * 60 * 30
};
function useFeedbacks({ onSuccess, ...options } = {}) {
	const client = useClient();
	const { data: feedback, status } = useQuery({
		queryKey: "feedback",
		queryFn: () => client("users/feedback").then((data) => data),
		onSuccess: async (feedback) => {
			await onSuccess?.(feedback);
		},
		...queryConfig,
		...options,
	});
	return { feedback: feedback ?? [], status };
}

function setPortfolioBookmark(portfolio) {
	const token = getToken();
	return api("users/bookmark/portfolio", {
		body: { _id: portfolio },
		token,
	});
}
function setBuildingBookmark(building) {
	const token = getToken();
	return api("users/bookmark/building", {
		body: { _id: building },
		token,
	});
}

export {
	sendFeedback,
	useFeedbacks,
	setPortfolioBookmark,
	setBuildingBookmark,
};
