import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { initializeGA } from "routes/gaTracker";
// Components
import App from "./App";
// Styles And Theme
import "./styles/index.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "styles/theme.js";

import { AppProviders } from "contexts";
ReactDOM.render(
	<ThemeProvider theme={Theme}>
		<CssBaseline />
		<AppProviders>
			<App />
		</AppProviders>
	</ThemeProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
if (serviceWorker.isLocalhost) initializeGA();
